<template>
  <div class="description fd-h-full fd-px-5 fd-pb-5 fd-pt-12">
    <b-tabs fill>
      <b-tab
        lazy
        v-for="(language, index) in languages"
        :key="index"
      >
        <template #title>
          <div class="fd-flex fd-items-center fd-justify-center fd-mt-2">
            <span class="fd-block">{{ language.name.charAt(0).toUpperCase() + language.name.slice(1) }}</span>
            <span v-if="language.required" class="fd-block fd--mt-2 fd-text-red-500">*</span>
          </div>
        </template>

        <div class="fd-mt-12">

          <div>
            <b-form-textarea id="description" v-model="language.description" rows="20" max-rows="20" style="font-size: 0.75rem !important; line-height: 1rem; !important;" />
          </div>

          <div
            v-if="language.code !== 'tr'"
            class="fd-flex fd-items-end fd-cursor-pointer fd-mt-8"
            @click="autoTranslate(language)"
          >
            <SvgLoader :name="'translateIcon'" />
            <span class="fd-block fd-ml-1 fd-text-theme-4 underline">Auto translate</span>
          </div>

          <div class="fd-w-full fd-flex fd-items-start fd-justify-end fd-mt-8">
            <b-button @click="save" variant="primary">Next</b-button>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BFormInput, BTabs, BTab, BFormGroup, BFormTextarea, BButton, BToast } from 'bootstrap-vue'
import TextEditor from "@/views/components/global/TextEditor";
import toast from "@/mixins/toast";

export default {
  name: "Description",
  components: {TextEditor, BFormGroup, BFormInput, BFormTextarea, BTabs, BTab, BButton, BToast},
  mixins: [toast],
  data() {
    return {
      validated: false,
      languages: [
        {
          id: 1,
          name: 'turkish',
          code: 'tr',
          required: true,
          description: '',
        },
        {
          id: 2,
          name: 'english',
          code: 'en',
          required: false,
          description: '',
        },
        {
          id: 3,
          name: 'persian',
          code: 'fa',
          required: false,
          description: '',
        },
        {
          id: 4,
          name: 'arabic',
          code: 'ar',
          required: false,
          description: '',
        },
        {
          id: 5,
          name: 'russian',
          code: 'ru',
          required: false,
          description: '',
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('project/getTemplateDescription', this.$route.params.templateId)
      .then(() => {
        this.dataPreparation()
      })
  },
  methods: {
    async save() {
      await this.checkLanguagesRequired()
      if (!this.validated) {
        this.makeToast('Turkish language is required')
        return;
      }

      let formData = new FormData()
      let languages = this.getLanguagesValues()

      formData.append('description', JSON.stringify(languages))

      this.$store.dispatch('project/updateTemplateDescription', {
        templateId: this.$route.params.templateId,
        data: formData
      })
      .then(() => {
        this.$router.push({
          name: 'projects.templates.features',
          params: {
            id: this.$route.params.id,
            templateId: this.$route.params.templateId
          }
        })
      })
    },
    async autoTranslate(language) {
      await this.checkLanguagesRequired()
      if (!this.validated) {
        this.makeToast('Turkish language is required')
        return;
      }
      const turkish = this.languages.find(item => item.code === 'tr')
      this.$store.dispatch('project/updateOnlineSettingAutoTranslate', {
        type: 'templates',
        data: {
          code: language.code,
          description: turkish.description,
        }
      })
        .then(response => {
          this.languages.forEach((item, index) => {
            if (item.code === language.code) {
              this.languages[index].description = response.data.result.description
            }
          })
        })
    },
    checkLanguagesRequired() {
      return this.languages.forEach(item => {
        if (!item.required) return

        if (item.description) {
          this.validated = true
        } else {
          this.validated = false
        }
      })
    },
    dataPreparation() {
      const data = this.$store.getters["project/templateDescription"]

      this.languages.forEach(item => {
        const lang = data.find(lang => {
          return lang.code.toLowerCase() === item.code.toLowerCase()
        })
        if (!lang) return
        item.id = lang.id
        item.description = lang.description
      })
    },
    getLanguagesValues() {
      return this.languages.map(item => {
        item = {
          id: item.id,
          description: item.description
        }
        return item
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.description {
  ::v-deep .tabs {
    .nav-tabs {
      margin-bottom: 0 !important;

      .active.nav-link {
        &:after {
          bottom: auto !important;
          top: 0 !important;
          height: 6px !important;
        }

        background: #FBFBFB !important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid #E6E7E6 !important;
        border-bottom: none !important;
      }
    }

    .tab-content {
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 30px;
      background: #FBFBFB !important;
      border: 1px solid #E6E7E6 !important;
    }
  }
}
</style>